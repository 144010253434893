import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class ServicesEdit extends React.Component {
  state = {
    baseURI: this.props.baseURI,
    courses: this.props.courses,
    selectedCourseIds: [],
    targetId: 0,
  };

  fetchSelectedIds() {
    axios({
      method: 'GET',
      url: `${this.props.baseURI}api/v1/services/${this.props.publicId}/fetch_selected_courses`
    }).then((response) => {
      this.setState({ selectedCourseIds: response.data.course_ids });
    });
  };

  add() {
    if (this.state.targetId.length > 0) {
      const targetIds = this.state.selectedCourseIds.map(id => Number(id));
      if (targetIds.indexOf(Number(this.state.targetId)) >= 0) {
        alert('既に追加されています');
      } else {
        axios({
          method: 'POST',
          url: `${this.state.baseURI}api/v1/services/${this.props.publicId}/add_course`,
          params: {
            course_id: this.state.targetId,
          }
        }).then((response) => {
          this.fetchSelectedIds();
          alert(response.data.message);
        });
      }
    } else {
      alert('コースを選択してください');
    }
  };

  remove(e) {
    const targetId = e.currentTarget.getAttribute('data-id');
    if (confirm('LPからこのコースを削除しますか？')) {
      axios({
        method: 'DELETE',
        url: `${this.state.baseURI}api/v1/services/${this.props.publicId}/remove_course`,
        params: {
          course_id: targetId,
        }
      }).then((response) => {
        this.fetchSelectedIds();
        alert(response.data.message);
      });
    }
  };

  change(e) {
    this.setState({ targetId: e.currentTarget.value });
  }

  componentDidMount() {
    this.fetchSelectedIds();
  };

  render() {
    let courses = this.state.courses.map((course, key) => <option value={course.id} key={key}>{course.name}</option>);

    let selectedContents = this.state.selectedCourseIds.map((courseId, key) => <div className="news__card" key={key}>
      <div className="flex items-center pd10">
        <h2>{this.state.courses.find((course) => { return (course.id === courseId); }).name}</h2>
        <div className="ml-auto pointer" data-id={courseId} onClick={this.remove.bind(this)}><i className="fas fa-times"></i></div>
      </div>
    </div>);

    return <div className="mt20">
      <div className="flex items-center">
        <select className="selector" onChange={this.change.bind(this)}>
          <option disabled selected>コースを選択してください</option>
          {courses}
        </select>
        <button className="ml10 selectBtn" onClick={this.add.bind(this)}>追加</button>
      </div>

      <div className="news__container mt20">
        <div className="news__wrapper">
          {selectedContents}
        </div>
      </div>
    </div>;
  }
}

ServicesEdit.propTypes = {
  baseURI: PropTypes.string,
  publicId: PropTypes.string,
  courses: PropTypes.array,
};
export default ServicesEdit
