import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class GroupsShow extends React.Component {
  state = {
    baseURI: this.props.baseURI,
    plans: this.props.plans,
    selectedPlanIds: [],
    targetId: 0,
  };

  fetchSelectedIds() {
    axios({
      method: 'GET',
      url: `${this.props.baseURI}/api/v1/groups/${this.props.publicId}/fetch_selected_plans`
    }).then((response) => {
      this.setState({ selectedPlanIds: response.data.plan_ids });
    });
  };

  add() {
    if (this.state.targetId > 0) {
      const targetIds = this.state.selectedPlanIds.map(id => Number(id));
      if (targetIds.indexOf(Number(this.state.targetId)) >= 0) {
        alert('既に追加されています');
      } else {
        axios({
          method: 'POST',
          url: `${this.state.baseURI}/api/v1/groups/${this.props.publicId}/add_plan`,
          params: {
            plan_id: this.state.targetId,
          }
        }).then((response) => {
          this.fetchSelectedIds();
          alert(response.data.message);
        });
      }
    } else {
      alert('プランを選択してください');
    }
  };

  remove(e) {
    const targetId = e.currentTarget.getAttribute('data-id');
    if (confirm('グループからこのプランを削除しますか？')) {
      axios({
        method: 'DELETE',
        url: `${this.state.baseURI}/api/v1/groups/${this.props.publicId}/remove_plan`,
        params: {
          plan_id: targetId,
        }
      }).then((response) => {
        this.fetchSelectedIds();
        alert(response.data.message);
      });
    }
  };

  change(e) {
    this.setState({ targetId: e.currentTarget.value });
  }

  componentDidMount() {
    this.fetchSelectedIds();
  };

  render() {
    let plans = this.state.plans.map((plan, key) => <option value={plan.id} key={key}>{plan.name}</option>);

    let selectedContents = this.state.selectedPlanIds.map((planId, key) => <div className="news__card" key={key}>
      <div className="flex items-center pd10">
        <h2>{this.state.plans.find((plan) => { return (plan.id === planId); }).name}</h2>
        <div className="ml-auto pointer" data-id={planId} onClick={this.remove.bind(this)}><i className="fas fa-times"></i></div>
      </div>
    </div>);

    return <div className="mt20">
      <div className="flex items-center">
        <select className="selector" onChange={this.change.bind(this)}>
          <option disabled selected>プランを選択してください</option>
          {plans}
        </select>
        <button className="ml10 selectBtn" onClick={this.add.bind(this)}>追加</button>
      </div>

      <div className="news__container mt20">
        <div className="news__wrapper">
          {selectedContents}
        </div>
      </div>
    </div>;
  }
}

GroupsShow.propTypes = {
  baseURI: PropTypes.string,
  publicId: PropTypes.string,
  plans: PropTypes.array,
};
export default GroupsShow
